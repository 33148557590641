<template>
  <div>

    <patient-therapy-form v-model="showAddTherapyForm" @after-save="onAfterSaveTherapy" :therapy="newPatientTherapy" ></patient-therapy-form>
    <survey-therapy-protocol-form-modal @after-save="onTherapyRowClick(currentTherapy)" v-model="showAddTherapyToSurveyForm" :therapy-protocol="currentTherapyProtocol"></survey-therapy-protocol-form-modal>

    <el-row>
      <el-col :span="12">
         <h4 id="patient-therapy-headline">Therapie</h4>
      </el-col>
      <el-col :span="12" class="text-right">
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        <h6>Diagnose</h6>
        <el-select size="medium" class="w-100" v-model="form.diagnosis" filterable :disabled="filterIsDisabled">
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
              v-for="item in allDiagnosis"
              :key="item.id"
              :label="(item.code + ', ' + item.title)"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :offset="1" :span="5">
        <h6>Behandlungssituation / Therapiemodalität</h6>
        <el-select size="medium" class="w-100" v-model="form.treatmentsituation" filterable :disabled="filterIsDisabled">
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
              v-for="item in allTreatmentSituations"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :offset="1" :span="4">
        <h6>Prädiktive Faktoren</h6>
        <el-select size="medium" class="w-100" v-model="form.biomarker" filterable :disabled="filterIsDisabled">
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
              v-for="item in allBiomarker"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :offset="1" :span="3">
        <h6>Zulassung</h6>
        <el-select size="medium" class="w-100" v-model="form.status" filterable :disabled="filterIsDisabled">
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
            v-for="item in allStatusOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :offset="1" :span="3">
        <h6>Hausliste</h6>
        <el-button :type="(form.only_protocol_own ? 'primary' : 'info')" @click="form.only_protocol_own = !form.only_protocol_own"><span v-if="form.only_protocol_own">Alle Therapien</span><span v-else>Nur Hausliste</span></el-button>
      </el-col>
    </el-row>

    <hr/>

    <h5>Therapieprinzip auswählen</h5>

    <el-row>
      <el-col :span="6" class="round">

        <el-table
            :data="filteredTherapies"
            style="width: 100%"
            :show-header="false"
            highlight-current-row
            @row-click="onTherapyRowClick"
            height="500"
            stripe>
          <el-table-column>
            <template #default="scope">
              {{ scope.row.code }}
              <el-tooltip
                class="box-item"
                effect="dark"
                content="inlabel"
                placement="top"
                v-if="therapyInInlabel(scope.row, 1)"
              >
                <el-tag  type="success" size="mini">In</el-tag>
              </el-tooltip>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="offlabel"
                placement="top"
                v-if="therapyInInlabel(scope.row, 0)"
              >
                <el-tag  type="danger" size="mini">off</el-tag>
              </el-tooltip>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Experimentell"
                placement="top"
                v-if="therapyInInlabel(scope.row, 2)"
              >
                <el-tag type="warning" size="mini">ex</el-tag>
              </el-tooltip>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Zulassung erloschen"
                placement="top"
                v-if="therapyInInlabel(scope.row, 3)"
              >
                <el-tag type="danger"  size="mini" style="text-decoration:line-through;" >in</el-tag>
              </el-tooltip>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Offlabel durch G-BA erlaubt"
                placement="top"
                v-if="therapyInInlabel(scope.row, 5)"
              >
                <i class="ml-1 text-danger el-icon el-icon-circle-close"></i>
              </el-tooltip>


              <el-tooltip v-if="therapyHasSurveys(scope.row)" placement="top">
                <template #content>
                  <div :key="survey.id" v-for="survey of surveysOfTherapy(scope.row)">{{survey.title_short}}</div>
                </template>
                <el-tag size="mini">S</el-tag>
              </el-tooltip>

            </template>
          </el-table-column>
        </el-table>

      </el-col>
      <el-col :span="18" v-if="!currentTherapy" v-loading="currentTherapyLoading">
      </el-col>
      <el-col :span="18" v-if="currentTherapy" >

        <div class="ml-5">

          <h5 class="text-dacy">{{currentTherapy.code}}</h5>
          <div class="mb-3">{{currentTherapy.title}}</div>
          <p class="text-danger" v-if="currentTherapy.special"><i class="el-icon-warning"></i> {{currentTherapy.special}}</p>

          <div v-if="currentTherapy.therapyProtocols && currentTherapy.therapyProtocols.length > 0">

            <h5 class="mt-4">Protokolle / Therapien</h5>
            <el-collapse class="border-top-0">
              <el-collapse-item :name="index" :key="therapyProtocol.protocol.id" v-for="(therapyProtocol, index) in currentTherapy.therapyProtocols">
                <template #title>
                  {{(therapyProtocol.meta && therapyProtocol.meta.protocolown ? therapyProtocol.meta.protocolown.title : therapyProtocol.protocol.title)}}
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="offlabel"
                    placement="top"
                    v-if="therapyProtocol.inlabel == 0"
                  >
                    <el-tag class="ml-1" size="mini" type="danger">off</el-tag>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="inlabel"
                    placement="top"
                    v-if="therapyProtocol.inlabel == 1"
                  >
                    <el-tag class="ml-1" size="mini" type="success">in</el-tag>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="Experimentell"
                    placement="top"
                    v-if="therapyProtocol.inlabel == 2"
                  >
                    <el-tag class="ml-1" size="mini" type="warning">ex</el-tag>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="Zulassung erloschen"
                    placement="top"
                    v-if="therapyProtocol.inlabel == 3"
                  >
                    <el-tag class="ml-1" style="text-decoration:line-through;" size="mini" type="danger">in</el-tag>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="Offlabel durch G-BA erlaubt"
                    placement="top"
                    v-if="therapyProtocol.inlabel == 5"
                  >
                    <i class="ml-1 text-danger el-icon el-icon-circle-close"></i>
                  </el-tooltip>
                  <el-tooltip
                    v-if="therapyNotInGER(therapyProtocol)"
                    class="box-item"
                    effect="dark"
                    content="In Deutschland nicht verfügbar"
                    placement="top"
                  >
                    <el-tag  class="ml-1" size="mini" type="danger" style="text-decoration: line-through;">BRD</el-tag>
                  </el-tooltip>

                  <el-tooltip v-if="therapyProtocol.meta && therapyProtocol.meta.surveys && therapyProtocol.meta.surveys.length > 0" placement="right">
                    <template #content>
                      <div :key="survey.id" v-for="survey of surveysOfTherapyProtocol(therapyProtocol)">{{survey.title_short}}</div>
                    </template>
                    <el-tag class="ml-1" size="mini">S</el-tag>
                  </el-tooltip>

                </template>
                <el-button class="float-right m-1" @click="selectTherapy(therapyProtocol.protocol)" v-if="btnAddTherapyLabel" type="warning">{{btnAddTherapyLabel}}</el-button>
                <el-button class="float-right m-1" @click="selectSurvey(therapyProtocol)" v-if="modules_enabled.survey && context === 'kb'" type="warning">Studie zuweisen</el-button>

                <div v-if="therapyProtocol.meta.protocolown"><h6>Protokollname DACY</h6>{{therapyProtocol.protocol.title}}</div>
                <div v-else-if="therapyProtocol.protocol.kz_title"><h6>Protokollkürzel</h6>{{therapyProtocol.protocol.kz_title}}</div>

                <div v-if="therapyProtocol.inlabel == 1">inlabel</div>
                <div v-if="therapyProtocol.inlabel == 0">offlabel</div>
                <div class="mt-3" v-if="therapyProtocol.specifics">
                  <h6>Besonderheiten</h6>
                  {{therapyProtocol.specifics}}
                </div>
                <div class="mt-3" v-if="therapyProtocol.duration">
                  <h6>Dauer</h6>
                  {{therapyProtocol.duration}}
                </div>
                <div v-if="therapyProtocol.protocol.drugs" class="mb-3 mt-3">
                  <h6>Fachinformationen</h6>
                  <div v-for="drug in therapyProtocol.protocol.drugs" :key="drug.id">
                    <span v-if="drug.pdf_link"><a href="javascript:void(0)" @click="openBrowser(drug.pdf_link)">{{drug.title}}</a></span>
                    <span v-else>{{drug.title}}</span>
                    <el-tooltip
                      v-if="!drug.available"
                      class="box-item"
                      placement="top"
                      content="In Deutschland nicht verfügbar"
                      effect="dark"
                    >
                      <el-tag  class="ml-1" size="mini" type="danger" style="text-decoration: line-through;">BRD</el-tag>
                    </el-tooltip>
                  </div>
                </div>
                <div v-if="therapyProtocol.protocol.description"><h6>Beschreibung</h6>{{therapyProtocol.protocol.description}}</div>
                <div v-if="therapyProtocol.protocol.special"><h6>Besonderheiten</h6>{{therapyProtocol.protocol.special}}</div>
                <div v-if="therapyProtocol.protocol.oral"><h6>Oral</h6>{{therapyProtocol.protocol.oral}}</div>

                <div v-if="showTherapyProtocolStudyResults && therapyProtocol.studyresults && therapyProtocol.studyresults.length > 0">
                  <h6 class="mt-2">Studienergebnisse</h6>
                  <el-collapse :model-value="0" accordion class="border-top-0 border-bottom-0">
                    <el-collapse-item :name="studyindex" :title="studyResult.study.title" :key="studyResult.id" v-for="(studyResult, studyindex) in therapyProtocol.studyresults">
                      <h6 v-if="studyResult.pro_con == 1">Endpunkte</h6>
                      <h6 v-if="studyResult.pro_con == 2">Einschränkungen</h6>
                      <study-result-description :description="studyResult.description" :literature="studyResult.study.literature"></study-result-description>
                      <h6 v-if="studyResult.study.literature.length" class="mt-2">Literatur</h6>
                      <p :key="literature.id" v-for="literature in studyResult.study.literature">
                        <span v-if="literature.link_pubmed"><a href="javascript:void(0)" @click="openBrowser(literature.link_pubmed)">{{literature.signature}}</a></span>
                        <span v-else>{{literature.signature}}</span>
                      </p>
                      <h6 class="mt-4" v-if="studyResult.study.ECOG.length">ECOG</h6>
                      <span class="mr-3" :key="ECOG.id" v-for="ECOG in studyResult.study.ECOG">
                        <el-tooltip placement="bottom">
                          <template #content><span v-if="ECOG.ecog_percent">{{ECOG.ecog_percent}}<br/></span>{{ ECOG.meta.description }}</template>
                          <el-tag>{{ECOG.meta.ecog}}</el-tag>
                        </el-tooltip>

                      </span>
                    </el-collapse-item>
                  </el-collapse>
                </div>

              </el-collapse-item>
            </el-collapse>

          </div>
          <div v-else>
            <el-button v-if="btnAddTherapyLabel" @click="selectTherapy(null)" type="warning">{{btnAddTherapyLabel}}</el-button>
          </div>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import PatientTherapyForm from "@/components/patient/PatientTherapyForm";
import StudyResultDescription from "../parts/StudyResultDescription";
import _ from "lodash";
import axios from "axios";
import SurveyTherapyProtocolFormModal from "../survey/SurveyTherapyProtocolFormModal";

export default {
  name: "PatientTherapy",
  props: ['clickAction', 'context'],
  components: {
    SurveyTherapyProtocolFormModal,
    StudyResultDescription,
    PatientTherapyForm
  },
  data() {
    return {
      newPatientTherapy: {
        meta: {
          therapy: {}
        }
      },
      showAddTherapyForm: false,
      showAddTherapyToSurveyForm: false,
      currentTherapy: null,
      currentTherapyLoading: false,
      currentTherapyProtocol: null,
      currentReferer: null,
      allTreatmentSituations: [],
      allBiomarker: [],
      allStatusOptions: [
        { title: 'Nur inlabel', obsolet: 0, inLabelIds: [1], id: 1 },
        { title: 'Inlabel und Offlabel', obsolet: 0, inLabelIds: [0, 1, 5], id: 2 },
        { title: 'Alle', obsolet: null, inLabelIds: [], id: 3 }
      ],
      filterTherapyProtocolIds: null,
      form: {
        therapies: [],
        diagnosis: null,
        treatmentsituation: null,
        biomarker: 18,
        only_protocol_own: false,
        only_active: true,
        status: null
      },
    }
  },
  mounted: async function () {

    if(this.$route.query) {
      if(this.$route.query.therapies) this.form.therapies = this.$route.query.therapies.split(',')
      if(this.$route.query.therapyProtocols) this.filterTherapyProtocolIds = this.$route.query.therapyProtocols.split(',').map((id) => parseInt(id))
      if(this.$route.query.referer) this.currentReferer = this.$route.query.referer
    }

    await this.getTherapies(this.form)
    await this.getOncologyDiagnosis()
    await this.getBiomarker()
    await this.getTreatmentsituations()

    this.allTreatmentSituations = {...this.treatmentsituations}
    this.allBiomarker = {...this.biomarker}
  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
    ...mapState('therapies', ['therapies']),
    ...mapState('diagnosis', ['oncologyDiagnosis']),
    ...mapState('biomarker', ['biomarker']),
    ...mapState('treatmentsituations', ['treatmentsituations']),
    ...mapGetters('config', ['modules_enabled']),
    btnAddTherapyLabel: function() {
      switch (this.clickAction) {
        case 'therapy-protocol-own-list':
          return 'Protokoll zur Hausliste hinzufügen'
        case 'knowledgebase':
          return false
        default:
          return 'Therapie zuweisen'
      }
    },
    filteredTherapies: function() {
      if(!this.therapies) return []
      if(this.clickAction && this.clickAction == 'therapy-protocol-own-list') {
        return _.filter(this.therapies, (t) => (t.therapyProtocols && t.therapyProtocols.length > 0))
      }
      else {
        return this.therapies
      }
    },
    filterIsDisabled: function() {
      if(this.currentReferer === 'therapyplan') return true
      else return false
    },
    showTherapyProtocolStudyResults: function() {
      if(this.currentReferer === 'therapyplan') return false
      else return true
    },
    allDiagnosis() {
      const filteredDiagnosis = []
      const addedIds = []
      if(this.currentPatient && this.currentPatient.diagnosis && this.currentPatient.diagnosis.length > 0) {
        for(const diagnosis of this.currentPatient.diagnosis) {
          if(diagnosis.is_primary && addedIds.indexOf(diagnosis.diagnosis_id) === -1) {
            filteredDiagnosis.push(diagnosis.meta.diagnosis)
            addedIds.push(diagnosis.diagnosis_id)
          }
        }
      }

      if(this.oncologyDiagnosis) {
        for(const diagnosis of this.oncologyDiagnosis) {
          if(addedIds.indexOf(diagnosis.id) === -1) {
            filteredDiagnosis.push(diagnosis)
          }
        }
      }

      return filteredDiagnosis

    }
  },
  watch: {
    'form.diagnosis': async function () {
      this.form.treatmentsituation = null
      this.form.biomarker = null
      await this.getTherapies({
        ...this.form,
        status: this.allStatusOptions.find(status => status.id === this.form.status)
      })
      this.filterTreatmentSituations()
      this.filterBiomarker()
    },
    'form.treatmentsituation': async function () {
      if(this.form.treatmentsituation !== null) {
        this.form.biomarker = null
        await this.getTherapies({
          ...this.form,
          status: this.allStatusOptions.find(status => status.id === this.form.status)
        })
        this.filterBiomarker()
      }
    },
    'form.biomarker':async function () {
      if(this.form.biomarker !== null) {
        await this.getTherapies({
          ...this.form,
          status: this.allStatusOptions.find(status => status.id === this.form.status)
        })
      }
    },
    'form.only_protocol_own':async function () {
      await this.getTherapies({
        ...this.form,
        status: this.allStatusOptions.find(status => status.id === this.form.status)
      })
    },
    'form.status':async function () {
      await this.getTherapies({
        ...this.form,
        status: this.allStatusOptions.find(status => status.id === this.form.status)
      })
    }
  },
  methods: {
    ...mapActions('diagnosis', ['getOncologyDiagnosis']),
    ...mapActions('biomarker', ['getBiomarker']),
    ...mapActions('treatmentsituations', ['getTreatmentsituations']),
    ...mapActions('therapies', ['getTherapies']),
    filterTreatmentSituations() {
      let filteredTreatmentSituations = []
      let addedTreatmentSituations = []
      let allTherapies = JSON.parse(JSON.stringify(this.therapies))

      for(const therapy of allTherapies) {

        if(therapy.treatmentSituation && addedTreatmentSituations.indexOf(therapy.treatmentSituation.id) === -1) {
          addedTreatmentSituations.push(therapy.treatmentSituation.id)
          filteredTreatmentSituations.push(therapy.treatmentSituation)
        }

      }
      filteredTreatmentSituations = _.sortBy(filteredTreatmentSituations, [function(o) { return o.title.toLowerCase(); }])
      this.allTreatmentSituations = filteredTreatmentSituations

    },
    filterBiomarker() {
      let filteredBiomarker = []
      let addedBiomarker = []
      let allTherapies = JSON.parse(JSON.stringify(this.therapies))

      for(const therapy of allTherapies) {

        if(therapy.biomarker && therapy.biomarker.length) {
          for(const biomarker of therapy.biomarker) {
            if(addedBiomarker.indexOf(biomarker.id) === -1) {
              addedBiomarker.push(biomarker.id)
              filteredBiomarker.push(biomarker)
            }
          }
        }

      }
      filteredBiomarker = _.sortBy(filteredBiomarker, [function(o) { return o.title.toLowerCase(); }])
      this.allBiomarker = filteredBiomarker

    },
    async onTherapyRowClick(row) {
      this.currentTherapy = null
      this.currentTherapyLoading = true
      const result = await axios.get('/therapies/' + row.id)
      if(result.status === 404) {
        this.$notify.error({
          message: "Therapie wurde nicht gefunden",
          position: 'bottom-left'
        });
      }
      else {
        this.currentTherapy = result.data
        if(this.filterTherapyProtocolIds && this.filterTherapyProtocolIds.length && this.filterTherapyProtocolIds.length > 0) {
          let newTherapyProtocols = []
          for(const therapyProtocol of this.currentTherapy.therapyProtocols) {
            if(this.filterTherapyProtocolIds.indexOf(therapyProtocol.id) !== -1) {
              newTherapyProtocols.push(therapyProtocol)
            }
          }
          this.currentTherapy.therapyProtocols = newTherapyProtocols
        }
      }
      this.currentTherapy.therapyProtocols = _.sortBy(this.currentTherapy.therapyProtocols, [function(o) { return (o.meta && o.meta.protocolown ? o.meta.protocolown.title.toLowerCase() : o.protocol.title.toLowerCase()) }])
      this.currentTherapyLoading = false
    },
    selectTherapy(protocol)  {

      if(this.clickAction && this.clickAction == 'therapy-protocol-own-list') {
        this.$emit('onTherapyProtocolSelect', protocol)
      }
      else {
        let newPatientTherapy = {}
        newPatientTherapy.meta = {}
        newPatientTherapy.meta.patient = this.currentPatient
        newPatientTherapy.meta.therapy = this.currentTherapy
        newPatientTherapy.meta.protocol = protocol
        newPatientTherapy.patient_id = this.currentPatient.id
        newPatientTherapy.therapy_id = this.currentTherapy.id
        newPatientTherapy.protocol_id = protocol ? protocol.id : null
        newPatientTherapy.diagnosis_id = this.form.diagnosis
        newPatientTherapy.treatmentsituation_id = this.form.treatmentsituation
        newPatientTherapy.date_start = new Date()
        this.newPatientTherapy = newPatientTherapy
        this.showAddTherapyForm = true
      }

    },
    selectSurvey(protocol) {
      this.showAddTherapyToSurveyForm = true
      this.currentTherapyProtocol = protocol
    },
    onAfterSaveTherapy() {
      this.form.treatmentsituation = null
      this.form.biomarker = null
      this.$router.push({ name: 'dashboard' })
    },
    openBrowser(url) {
      if(window.gui) window.gui.send('open-link', url)
      else window.open(url, "_blank");
    },
    therapyInInlabel(therapy, needle) {
      const groups = _.groupBy(therapy.therapyProtocols, 'inlabel')
      return !!groups[needle]
    },
    therapyNotInGER(therapyProtocol) {
      if(!therapyProtocol.protocol) return false
      return _.find(therapyProtocol.protocol.drugs, (p) => p.available === false)
    },
    therapyHasSurveys(therapy) {
      if(!therapy.therapyProtocols) return false
      return _.find(therapy.therapyProtocols, (p) => (p.meta && p.meta.surveys && p.meta.surveys.length > 0))
    },
    surveysOfTherapyProtocol(therapyProtocol) {
      let therapySurveys = []
      if(therapyProtocol.meta && therapyProtocol.meta.surveys && therapyProtocol.meta.surveys.length > 0) {
        for(const tp of therapyProtocol.meta.surveys) {
          therapySurveys.push(tp.survey)
        }
      }
      return therapySurveys
    },
    surveysOfTherapy(therapy) {
      if(!therapy.therapyProtocols) return []
      let therapySurveys = []
      for(const p of therapy.therapyProtocols) {
        if(p.meta && p.meta.surveys && p.meta.surveys.length > 0) {
          for(const tp of p.meta.surveys) {
            therapySurveys.push(tp.survey)
          }
        }
      }
      return therapySurveys
    }
  }
}
</script>
<style>
.text-dacy, .el-collapse-item__header {
  color:#cf9236;
}

.el-collapse-item .el-collapse-item .el-collapse-item__header {
  color:#00A;
  font-size:15px;
}

.el-collapse-item__header {
  font-size:16px;
}
</style>
